@import "./fonts/roboto.css";

$white: #fff;
$black: #000000;
$darkBlue: #063462;
$lightBlue: #9DD1FF;
$yellow: #fec300;
$blue: #1a70c1;
$gray: #eeeff0;
$textColor: #262e3a;

* {
  box-sizing: border-box;
}

.App {
  font-family: Roboto, sans-serif;
  color: $textColor;

  a {
    color: $blue;
  }

  .rcw-launcher {
    background-color: $yellow;
    padding: 0;
    width: 52px;
    height: 52px;
    margin: 10px 20px 20px;
    animation-duration: .5s;
    animation-name: c;
    animation-fill-mode: forwards;

    img {
      display: none;
    }

    &:before {
      content: '';
      width: 100%;
      height: 30px;
      display: block;
      background: url('./images/icon-chat.svg') no-repeat center center / contain;
    }

    &.rcw-hide-sm {
      animation-duration: .5s;
      animation-name: b;
      animation-fill-mode: forwards;

      &:before {
        height: 20px;
        background-image: url("./images/icon-cross.svg");
      }
    }
  }

  .rcw-widget-container {
    max-width: 490px;
    margin: 0;
  }

  .rcw-conversation-container {
    margin-right: 20px;

    .avatar {
      width: 36px;
      height: 36px;
      position: absolute;
      top: 0;
      left: 0;
    }

    .rcw-header {
      padding: 10px 14px;
      background: $yellow url("./images/head_backgr.jpg") no-repeat center center / cover;
      color: $textColor;
      text-align: left;

      span {
        padding-left: 48px;
        font-size: 0.8125rem;
      }
    }

    .rcw-title {
      margin: 0;
      padding: 0 0 0 48px;
      position: relative;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.22;
      letter-spacing: 0.2px;
    }
  }

  .rcw-messages-container {
    height: 60vh;
    max-height: 60vh;
  }

  .rcw-message {
    margin: 8px 14px;
  }

  .rcw-custom-response-message {
    display: flex;
    align-items: flex-end;
  }

  .rcw-message-text {
    word-break: break-word;
    overflow-wrap: break-word;

    p {
      margin: 10px 0;
    }
  }

  .rcw-client {
    .rcw-message-text {
      max-width: 380px;
      padding: 0 12px;
      border-radius: 12px 12px 0 12px;
      background-color: $blue;
      font-size: 0.875rem;
      line-height: 1.3;
      color: $white;
    }
  }

  .rcw-response {
    .rcw-message-text {
      max-width: 380px;
      padding: 0 12px;
      border-radius: 12px 12px 12px 0;
      background-color: $gray;
      font-size: 0.875rem;
      line-height: 1.3;
      color: $textColor;
    }
  }

  .quick-buttons-container {
    white-space: normal;
    padding: 0;

    .quick-buttons {
      text-align: left;

      .quick-list-button {
        display: block;
        margin: 10px 0;

        .quick-button {
          display: block;
          margin: 0;
          padding: 10px 10px 10px 34px;
          width: 240px;
          border-radius: 12px;
          border: solid 1px transparentize($blue, 0.7);
          background: url("./images/icon-radio-off.svg") no-repeat 11px 12px;
          font-size: 0.875rem;
          font-weight: normal;
          line-height: 1.3;
          color: $blue;
          text-align: left;

          &:hover {
            border-color: $blue;
          }

          &:active {
            background-color: transparent;
            background-image: url("./images/icon-radio-on.svg");
          }
        }

        &-reset {
          .quick-button {
            background: transparentize($yellow, 0.3) none;
            border-color: $yellow;
            font-weight: 500;
            text-align: center;
            color: $textColor;
            padding: 10px 14px;

            &:hover,
            &:active {
              background: $yellow none;
              border-color: $yellow;
            }
          }
        }
      }
    }
  }

  .buttons-done {
    .quick-buttons-container {
      .quick-buttons {
        .quick-list-button {
          .quick-button {
            background-color: $gray;
            border-color: darken($gray, 10%);
            cursor: default;
            color: darken($gray, 30%);

            &:hover,
            &:active {
              background-image: url("./images/icon-radio-off.svg");
              background-color: $gray;
              border-color: darken($gray, 10%);
            }
          }

          &.active {
            .quick-button {
              background-image: url("./images/icon-radio-on-old.svg");
            }
          }
        }
      }
    }
  }

  .rcw-new-message {
    font-size: 0.8125rem;

    &:disabled {
      & ~ .rcw-send {
        display: none;
      }
    }
  }

  .rcw-sending-loader {
    height: 10px;
  }

  .rcw-typing-loader {
    height: 8px;
  }

  &.server-is-learning {
    .rcw-sender {
      background-color: #fff;
    }

    .rcw-new-message,
    .rcw-send {
      display: none;
    }

    .rcw-messages-container {
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: 'К сожалению, в данный момент я переобучаюсь, чтобы еще точнее отвечать на ваши запросы. Обратитесь чуть позже или задайте свой вопрос Дежурному инспектору!';
        display: block;
        width: 320px;
        padding-top: 174px;
        text-align: center;
        background: url("./images/icon-bot-study.svg") no-repeat center top;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.22;
        color: $textColor;
      }

      .rcw-message,
      .loader {
        display: none;
      }
    }
  }
}

html[data-visuallyimpaired-theme=white]  {
  .App {
    .rcw-launcher {
      background-color: $white;
      border: 1px solid $black;

      .rcw-badge {
        background-color: $white;
        color: $black;
        border: 1px solid $black;
      }
    }

    .rcw-conversation-container {
      border: 1px solid $black;
      box-shadow: none;

      .rcw-header {
        background-image: none;
        border-bottom: 1px solid;
      }

      .rcw-message-text {
        border: 1px solid;
      }

      .rcw-sender {
        border: 1px solid;
      }

      .quick-button {
        background: url("./images/icon_radio_off-black.svg") no-repeat 11px 12px !important;

        &:active {
          background-image: url("./images/icon_radio_on-black.svg") !important;
        }
      }

      .buttons-done {
        .quick-list-button.active {
          .quick-button {
            background-image: url("./images/icon-radio-on-old.svg") !important;
          }
        }
      }

      .rcw-send-icon {
        filter: brightness(0);
        background-color: transparent !important;
      }

      * {
        background-color: $white !important;
        border-color: $black !important;
        color: $black !important;
      }
    }
  }
}

html[data-visuallyimpaired-theme=black]  {
  .App {
    .rcw-launcher {
      background-color: $black;
      border: 1px solid $white;

      .rcw-badge {
        background-color: $black;
        color: $white;
        border: 1px solid $white;
      }

      &:before {
        background-image: url("./images/icon-chat-white.svg");
      }

      &.rcw-hide-sm {
        &:before {
          background-image: url("./images/icon-cross-white.svg");
        }
      }
    }

    .rcw-conversation-container {
      border: 1px solid $white;
      box-shadow: none;

      .rcw-header {
        background-image: none;
        border-bottom: 1px solid;
      }

      .rcw-message-text {
        border: 1px solid;
      }

      .rcw-sender {
        border: 1px solid;
      }

      .quick-button {
        background: url("./images/icon_radio_off-white.svg") no-repeat 11px 12px !important;

        &:active {
          background-image: url("./images/icon_radio_on-white.svg") !important;
        }
      }

      .buttons-done {
        .quick-list-button.active {
          .quick-button {
            background-image: url("./images/icon-radio-on-old.svg") !important;
          }
        }
      }

      * {
        background-color: $black !important;
        border-color: $white !important;
        color: $white !important;
      }
    }
  }
}

html[data-visuallyimpaired-theme=blue] {
  .App {
    .rcw-launcher {
      background-color: $lightBlue;
      border: 1px solid $darkBlue;

      .rcw-badge {
        background-color: $lightBlue;
        color: $darkBlue;
        border: 1px solid $darkBlue;
      }

      &:before {
        background-image: url("./images/icon-chat-dark-blue.svg");
      }

      &.rcw-hide-sm {
        &:before {
          background-image: url("./images/icon-cross-dark-blue.svg");
        }
      }
    }

    .rcw-conversation-container {
      border: 1px solid $darkBlue;
      box-shadow: none;

      .rcw-header {
        background-image: none;
        border-bottom: 1px solid;
      }

      .rcw-message-text {
        border: 1px solid;
      }

      .rcw-sender {
        border: 1px solid;
      }

      .quick-button {
        background: url("./images/icon_radio_off-black.svg") no-repeat 11px 12px !important;

        &:active {
          background-image: url("./images/icon_radio_on-black.svg") !important;
        }
      }

      .buttons-done {
        .quick-list-button.active {
          .quick-button {
            background-image: url("./images/icon-radio-on-old.svg") !important;
          }
        }
      }

      .rcw-send-icon {
        filter: brightness(0);
        background-color: transparent !important;
      }

      * {
        background-color: $lightBlue !important;
        border-color: $darkBlue !important;
        color: $darkBlue !important;
      }
    }
  }
}

html[data-visuallyimpaired-images=false] {
  .avatar {
    display: none;
  }

  .rcw-avatar {
    display: none;
  }

  .rcw-conversation-container {
    .rcw-title {
      padding-left: 0;
    }

    .rcw-header {
      span {
        padding-left: 0;
      }
    }
  }
}

html[data-visuallyimpaired-kerning=sm] * {
  letter-spacing: 0 !important;
}

html[data-visuallyimpaired-kerning=md] * {
  letter-spacing: 1px !important;
}

html[data-visuallyimpaired-kerning=lg] * {
  letter-spacing: 2px !important;
}

html[data-visuallyimpaired-fontsize=md] {
  font-size: 24px;

  .App {
    .rcw-conversation-container {
      .quick-button {
        background-position-y: 15px !important;
      }
    }
  }
}

html[data-visuallyimpaired-fontsize=lg] {
  font-size: 32px;

  .App {
    .rcw-conversation-container {
      .quick-button {
        background-position-y: 20px !important;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .App {
    .rcw-widget-container {
      max-width: 490px;
      height: auto;
      margin: 0;
      width: 100%;
    }

    .rcw-conversation-container {
      display: block;
      height: auto;
      margin-right: 0;

      .rcw-header {
        border-radius: 12px 12px 0 0;
        position: relative;
      }

      .rcw-close-button {
        display: none;
      }
    }

    .rcw-messages-container {
      height: 60vh;
      max-height: 60vh;
    }

    .rcw-sender {
      border-radius: 0 0 12px 12px;
    }

    .rcw-launcher {
      position: static;
      margin: 5px;
    }

    .rcw-hide-sm {
      display: block;
    }
  }
}
